import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import type {
  MessagePageOrReplyPageAndParams,
  MessageParamPages
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserComponent, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { getAsEnum } from '@aurora/shared-utils/helpers/objects/EnumHelper';
import type { ParsedUrlQuery } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import { getLog } from '@aurora/shared-utils/log';
import React, { useCallback, useEffect, useId, useMemo, useRef } from 'react';
import useTranslation from '../../useTranslation';
import type { FeedbackDetails } from './ActionFeedbackHelper';
import { getActionFeedbackVariant, isValidFeedback } from './ActionFeedbackHelper';
import ActionFeedbacks from './ActionFeedbackTypes';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';

const log = getLog(module);

/**
 * Display the toast alert based on query Parameter
 *
 * @author Amit Agrawal, Adam Ayres
 */
const ActionFeedback: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    formatMessage,
    loading: textLoading,
    FormattedMessage
  } = useTranslation(EndUserComponent.ACTION_FEEDBACK);
  const uid = useId();
  const { addToast } = useToasts();
  const { router, Link } = useEndUserRoutes();
  const actionIdString = router.getUnwrappedQueryParam(EndUserQueryParams.ACTION_FEEDBACK, '');
  const actionId = getAsEnum(actionIdString, ActionFeedbacks);
  const isRemovingQueryParams = useRef<boolean>(false);

  /**
   * Function to get toast message for related url action feedback with link to archived content
   */
  const getToastMessageForRelatedUrl = useCallback(() => {
    const archivedMessageUrl = router.getUnwrappedQueryParam(
      EndUserQueryParams.ARCHIVED_MESSAGE_URL,
      null
    );
    if (archivedMessageUrl) {
      const { route, params } = router.getRouteAndParamsByPath(archivedMessageUrl);
      const ToastMessage: React.FC = () => {
        return (
          <FormattedMessage
            id={`relatedUrl.archivalLink.flyoutMessage`}
            values={{
              link: (...chunks) =>
                (
                  <Link<MessagePageOrReplyPageAndParams>
                    route={route}
                    params={params as MessageParamPages}
                    query={{
                      [EndUserQueryParams.ARCHIVED_CONTENT]: 'true'
                    }}
                    legacyBehavior={true}
                  >
                    <a>{chunks}</a>
                  </Link>
                ) as React.ReactNode
            }}
          />
        );
      };
      return ToastMessage;
    } else {
      return null;
    }
  }, [FormattedMessage, Link, router]);

  const actionFeedbackMessageMap: Record<string, React.FC> = useMemo(
    () => ({
      [ActionFeedbacks.REDIRECT_TO_RELATED_PAGE]: getToastMessageForRelatedUrl()
    }),
    [getToastMessageForRelatedUrl]
  );

  /**
   * Return the toast message for the action feedback
   *
   * @returns the toast message
   */
  const getToastMessage = useCallback(
    (queryParameters: EndUserQueryParams[], query: ParsedUrlQuery, action: ActionFeedbacks) => {
      let keys = null;
      if (queryParameters) {
        // eslint-disable-next-line unicorn/no-array-reduce,unicorn/prefer-object-from-entries
        keys = queryParameters.reduce<Record<string, string | string[]>>(
          (accumulator, parameter) => {
            return {
              ...accumulator,
              [parameter]: query[parameter]
            };
          },
          {}
        );
      }
      return actionFeedbackMessageMap[action] ?? formatMessage(`${action}.message`, keys);
    },
    [actionFeedbackMessageMap, formatMessage]
  );

  /**
   * Stores the parsed URL query to the components local state so that it can be used later
   *
   * Also, removes the action feedback related query parameters from the URL (shallowly)
   * when the component renders for the first time (componentDidMount)
   */
  useEffect(() => {
    if (
      !isRemovingQueryParams.current &&
      actionId &&
      !textLoading &&
      isValidFeedback(actionId, router.query)
    ) {
      isRemovingQueryParams.current = true;
      const {
        toastVariant,
        alertVariant,
        queryParameters,
        autohide,
        persistRouteChange
      }: FeedbackDetails = getActionFeedbackVariant(actionId);
      let parametersToRemove = [EndUserQueryParams.ACTION_FEEDBACK, EndUserQueryParams.TIMESTAMP];
      if (queryParameters) {
        parametersToRemove = [...parametersToRemove, ...queryParameters];
      }
      const message = getToastMessage(queryParameters, router.query, actionId);
      const toastProps: ToastProps = {
        id: `action-feedback-${uid}`,
        alertVariant,
        message,
        title: formatMessage(`${actionId}.title`),
        toastVariant,
        autohide: autohide !== false,
        delay: 4000
      };
      // add persistRouteChange to the toast props only if a boolean value is provided
      if (persistRouteChange !== undefined) {
        toastProps.persistRouteChange = persistRouteChange;
      }
      router
        .removeQueryParam(parametersToRemove)
        .then(() => {
          return addToast(toastProps);
        })
        .finally(() => {
          isRemovingQueryParams.current = false;
        })
        .catch(error => {
          log.error(error, 'Unable to remove query params: %s', parametersToRemove);
        });
    }
  }, [actionId, addToast, formatMessage, getToastMessage, router, textLoading, uid]);

  return null;
};

export default ActionFeedback;
