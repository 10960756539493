import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import type { ParsedUrlQuery } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import ActionFeedbacks from './ActionFeedbackTypes';

export interface FeedbackDetails {
  /**
   * Toast variant
   */
  toastVariant: ToastVariant;
  /**
   * Alert variant
   */
  alertVariant: ToastAlertVariant;
  /**
   * Whether the toast is aggressive in nature (should persist until user dismisses it)
   */
  autohide?: boolean;
  /**
   * Query params for a specific action feedback
   */
  queryParameters?: EndUserQueryParams[];
  /**
   * Whether the toast should be persisted across route changes
   */
  persistRouteChange?: boolean;
}

const FLYOUT_DANGER = { toastVariant: ToastVariant.FLYOUT, alertVariant: ToastAlertVariant.DANGER };
const FLYOUT_WARNING = {
  toastVariant: ToastVariant.FLYOUT,
  alertVariant: ToastAlertVariant.WARNING
};

const FLYOUT_SUCCESS = {
  toastVariant: ToastVariant.FLYOUT,
  alertVariant: ToastAlertVariant.SUCCESS
};

const FlYOUT_INFO = {
  toastVariant: ToastVariant.FLYOUT,
  alertVariant: ToastAlertVariant.INFO
};

const actionFeedbackMap: Record<ActionFeedbacks, FeedbackDetails> = {
  [ActionFeedbacks.JOINED_GROUP_HUB]: FLYOUT_SUCCESS,
  [ActionFeedbacks.OCCASION_ATTENDING]: FLYOUT_SUCCESS,
  [ActionFeedbacks.OCCASION_INTERESTED]: FLYOUT_SUCCESS,
  [ActionFeedbacks.GROUP_HUB_INVITE_NOT_FOUND]: FLYOUT_DANGER,
  [ActionFeedbacks.GROUP_HUB_NOT_FOUND]: FLYOUT_DANGER,
  [ActionFeedbacks.EXISTING_GROUP_HUB_MEMBER]: {
    toastVariant: ToastVariant.FLYOUT,
    alertVariant: ToastAlertVariant.INFO
  },
  [ActionFeedbacks.ACCOUNT_LOCKED]: {
    toastVariant: ToastVariant.BANNER,
    alertVariant: ToastAlertVariant.DANGER,
    autohide: false,
    queryParameters: [EndUserQueryParams.LOCKOUT_TIME]
  },
  [ActionFeedbacks.EDITED_GROUPHUB]: FLYOUT_SUCCESS,
  [ActionFeedbacks.LEFT_GROUPHUB]: FLYOUT_SUCCESS,
  [ActionFeedbacks.DELETED_GROUPHUB]: FLYOUT_SUCCESS,
  [ActionFeedbacks.GROUPHUB_CREATED]: {
    ...FLYOUT_SUCCESS,
    queryParameters: [EndUserQueryParams.GROUPHUB_NAME]
  },
  [ActionFeedbacks.ACCOUNT_CLOSED]: FLYOUT_SUCCESS,
  [ActionFeedbacks.ACCOUNT_CLOSED_FOR_USER]: {
    ...FLYOUT_SUCCESS,
    queryParameters: [EndUserQueryParams.USER_NAME]
  },
  [ActionFeedbacks.RESET_TOKEN_EXPIRED]: { ...FLYOUT_DANGER, autohide: false },
  [ActionFeedbacks.INVALID_URL]: { ...FLYOUT_DANGER, autohide: false },
  [ActionFeedbacks.INVITE_TOKEN_INVALID]: { ...FLYOUT_WARNING, autohide: false },
  [ActionFeedbacks.INVITE_TOKEN_ERROR]: { ...FLYOUT_DANGER, autohide: false },
  [ActionFeedbacks.PAGE_NOT_FOUND]: FlYOUT_INFO,
  [ActionFeedbacks.OCCASION_NOT_FOUND]: FLYOUT_DANGER,
  [ActionFeedbacks.REDIRECT_TO_RELATED_PAGE]: {
    ...FLYOUT_WARNING,
    autohide: false,
    queryParameters: [EndUserQueryParams.ARCHIVED_MESSAGE_URL],
    persistRouteChange: false
  },
  [ActionFeedbacks.REDIRECT_TO_RELATED_PAGE_FOR_BASE_USERS]: {
    ...FlYOUT_INFO
  }
};

export const validFeedbackDuration = 30000;

/**
 * Function that returns details required to display an action feedback
 *
 * @param feedback id of the action feedback
 * @returns {FeedbackDetails} Details required to display the action feedback
 */
function getActionFeedbackVariant(feedback: ActionFeedbacks): FeedbackDetails {
  return actionFeedbackMap[feedback];
}

/**
 * Function to check if an action feedback is valid and has all required query parameters
 * (extra query parameters may be required to display dynamic text in the feedback message)
 *
 * @param feedback the feedback
 * @param query parsed query parameters in the URL
 * @returns {boolean} whether the action feedback is valid
 */
function isValidFeedback(feedback: ActionFeedbacks, query: ParsedUrlQuery) {
  const feedbackDetails = getActionFeedbackVariant(feedback);
  if (!feedbackDetails) {
    return false;
  }

  const timestampString = query[EndUserQueryParams.TIMESTAMP];
  const timestamp = Number(timestampString);
  if (!timestamp) {
    return false;
  }

  const { queryParameters } = feedbackDetails;
  const currentTimestamp = Date.now();
  const isExpired = Number.isNaN(Number(timestamp))
    ? true
    : currentTimestamp - Number(timestamp) > validFeedbackDuration ||
      currentTimestamp < Number(timestamp);
  if (isExpired) {
    return false;
  }
  let isValidRequest = true;
  if (queryParameters) {
    queryParameters.every(parameter => {
      const queryValue = query[parameter];
      if (!queryValue) {
        isValidRequest = false;
        return false;
      }
      return true;
    });
  }
  return isValidRequest;
}

export { getActionFeedbackVariant, isValidFeedback };
